<template>
  <div>
    <div>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <tinymce
      id="tinymce"
      ref="tinymceRefs"
      :texts="html"
      @changes="getChangeData"
    ></tinymce>
    <div id="forms" style="margin: 0px 50px !important">
      <div v-html="html"></div>
    </div>
  </div>
</template>

<script>
import tinymce from "@/components/tinymce";
import print from "print-js";

export default {
  name: "gzlxd",
  components: {
    tinymce,
  },
  data() {
    return {
      html:
        '<p style="text-align: center; font-size: 20px;">&nbsp;</p>\n' +
        '<p style="text-align: center; font-size: 20px;"><strong><span style="font-size: 36px; font-family: FangSong, serif;">工 作 联 系 单</span></strong></p>\n' +
        '<p style="text-align: center; font-size: 20px;">&nbsp;</p>\n' +
        '<p style="text-align: justify;"><span style="font-family: FangSong, serif;"><span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>（科室、所）：</span></p>\n' +
        '<p style="padding-left: 40px; text-align: justify;"><span style="font-family: FangSong, serif;">在&nbsp; 综&nbsp; 合&nbsp; 验&nbsp; 收&nbsp; &nbsp;工&nbsp; 作&nbsp; 中&nbsp; &nbsp;发&nbsp; 现&nbsp; 坐&nbsp; &nbsp;落&nbsp; 于</span></p>\n' +
        '<p><span style="font-family: FangSong, serif;">的</span></p>\n' +
        '<p style="text-align: justify;"><span style="font-family: FangSong, serif;"><span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>单位（个人）建议</span></p>\n' +
        '<p style="text-align: justify;"><span style="font-family: FangSong, serif;">的<span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>项目（地块出让合同编号：<span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>）</span></p>\n' +
        '<p style="text-align: justify;"><span style="font-family: FangSong, serif;">存在以下问题：</span></p>\n' +
        '<p style="text-align: justify; padding-left: 40px;"><span style="font-family: FangSong, serif;">1、<span style="border-bottom: 1px solid black;display:i">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></p>\n' +
        '<p style="text-align: justify; padding-left: 40px;"><span style="font-family: FangSong, serif;">2、<span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></p>\n' +
        '<p style="text-align: justify; padding-left: 40px;"><span style="font-family: FangSong, serif;">3、<span style="border-bottom: 1px solid black;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span></p>\n' +
        '<p style="text-align: justify; padding-left: 40px;"><span style="font-family: FangSong, serif;">特此告知。</span></p>\n' +
        '<p style="text-align: right;">&nbsp;</p>\n' +
        '<p style="text-align: right;"><span style="font-family: FangSong, serif;">综合验收办&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>\n' +
        '<p style="text-align: right;"><span style="font-family: FangSong, serif;">年&nbsp; &nbsp;月&nbsp; &nbsp;日&nbsp; &nbsp; &nbsp;&nbsp;</span></p>\n' +
        '<p style="text-align: left;">&nbsp;</p>',
    };
  },
  mounted() {},
  methods: {
    getChangeData(res) {
      this.html = res;
      console.log("22222222222", res);
    },
    print() {
      this.$refs.tinymceRefs.sendData();
      setTimeout(() => {
        print({
          printable: "forms",
          type: "html",
          scanStyles: false,
          css: "/static/sjfasc.css",
          documentTitle: "",
        });
      }, 10);
    },
  },
};
</script>

<style scoped>
#forms {
  display: none;
}
</style>
